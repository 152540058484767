<script setup lang="ts">
// Components
import ProductListCard from '../components/cards/productListCard.vue';

// Store
const productService = productStore();

// Data
onMounted(async () => {
  await productService.fetchProducts();
});
</script>
<template>
  <div class="flex flex-col w-full h-full bg-gray-50">
    <ui-header :title="$t('product.products')" />
    <div class="flex justify-start flex-col gap-4 p-4 overflow-y-auto">
      <product-list-card />
    </div>
  </div>
</template>
