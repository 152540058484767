import { useI18n } from 'vue-i18n';
import type { Header } from '~/components/ui/data/DataTable.vue';
import type { TableAction } from '~/components/ui/data/DataTableActions.vue';

export const useProductTable = () => {
  // Composables
  const { t } = useI18n();

  const headers: Header[] = [
    {
      label: t('product.headers.identifier'),
      key: 'client_product_id',
      sortable: 'client-product-id',
    },
    {
      label: t('product.headers.label'),
      key: 'label',
    },
    {
      label: t('product.headers.epi_average'),
      key: 'epi',
      sortable: 'epi',
    },
    {
      label: t('product.headers.last_production'),
      key: 'date',
      sortable: 'last-production',
    },
    {
      label: t('product.headers.quantity_last_3_months'),
      key: 'quantity',
    },
    {
      label: '',
      key: 'action',
      align: 'center',
    },
  ];

  const actions = computed((): TableAction[] => {
    return [
      {
        key: 'see',
        label: t('product.action.see_details'),
        icon: 'Eye',
        iconStokeWidth: 2,
      },
      {
        key: 'edit',
        label: t('product.action.edit_label'),
        icon: 'Pen',
        iconStokeWidth: 2,
      },
    ];
  });

  return {
    headers,
    actions,
  };
};
