<script setup lang="ts">
import dayjs from 'dayjs';
import { useProductTable } from '../../composables/productTable';
import { useProduct } from '../../composables/useProduct';

// Components
import ManageProductModal from '~/app-modules/product/components/modal/manageProductModal.vue';
import type { ProductsQueryListQuery } from '~/types/graphql/graphql';

// Services
const service = productStore();

// Composables
const router = useRouter();
const { headers, actions } = useProductTable();
const { getNewEPIType } = useEPI();
const { getLastProduction, getAverageEPI, seletedUnit } = useProduct();

// Data
const modal = ref<boolean>(false);
const productId = ref<number | null>(null);

// Methods
const openCrispChat = () => {
  window.$crisp.push(['do', 'chat:show']);
  window.$crisp.push(['do', 'chat:open']);
};

const getQuantityForLastThreeMonth = (item: ProductsQueryListQuery['products'][0]) => {
  let quantity: number = 0;

  item.fabrication_order_products.forEach((fabricationOrdersProducts) => {
    if (fabricationOrdersProducts.fabrication_order?.start) {
      const start = dayjs(fabricationOrdersProducts.fabrication_order?.start);
      if (start.isAfter(dayjs().subtract(3, 'month'))) {
        quantity += fabricationOrdersProducts.fabrication_order.quantity;
      }
    }
  });

  return quantity.toFixed(2) + ' ' + (item.fabrication_order_products[0]?.fabrication_order?.area?.unit?.symbol ?? 'kg');
};

const clickItem = async (event: string, item: ProductsQueryListQuery['products'][0]) => {
  if (event === 'edit') {
    productId.value = item.id;
    modal.value = true;
  } else if (event === 'see') {
    router.push(`/product/${item.id}`);
  }
};

onMounted(() => {
  service.updateFilter({
    name: 'last-production',
    type: 'desc',
  });
});
</script>

<template>
  <ui-card :title="$t('product.list_of_products')">
    <template #header>
      <ui-search-bar v-model="service.search" />
    </template>
    <ui-data-table
      :loading="service.loading"
      :items="service.getProductFiltered"
      :headers="headers"
      :error="service.error"
      :items-per-page="12"
      :filter="service.getFilter"
      @click-row="router.push(`/product/${$event.id}`)"
      @update-filter="service.updateFilter($event)"
    >
      <!-- Header -->
      <template #header-epi>
        <ui-dropdown v-model="seletedUnit" size="sm" :items="getNewEPIType()" :placeholder="$t('product.headers.epi_average')" />
      </template>

      <!-- No data -->
      <template #no-data-message>
        <p v-if="service.search" class="mt-1 text-sm text-gray-500">
          {{ $t('global.no_data_for_this_filter') }}
        </p>
        <p v-else class="mt-1 text-sm text-gray-500">
          <span class="text-blue-500 hover:text-blue-800 cursor-pointer" @click="openCrispChat()">{{ $t('global.contact_us') }} </span>
          {{ $t('global.to_import_product_in_nrjx') }}
        </p>
      </template>

      <!-- Body -->
      <template #item-epi="{ item }">
        <ui-badge
          v-if="item.fabrication_order_products[0]?.fabrication_order?.fabrication_order_epis[0]?.consumption_kwh === 0"
          color="orange"
        >
          {{ $t('global.no_data') }}
        </ui-badge>
        <template v-else>{{ getAverageEPI(item).averageWithUnit }}</template>
      </template>
      <template #item-date="{ item }">
        {{ getLastProduction(item) }}
      </template>
      <template #item-quantity="{ item }">
        {{ getQuantityForLastThreeMonth(item) }}
      </template>
      <template #item-client_product_id="{ item }">
        <ui-badge color="blue">
          {{ item.client_product_id }}
        </ui-badge>
      </template>
      <template #item-action="{ item }">
        <ui-data-table-actions :items="actions" @click-item="clickItem($event, item)" />
      </template>
    </ui-data-table>
  </ui-card>

  <!-- Modales -->
  <manage-product-modal v-model="modal" :product-id="productId" />
</template>
