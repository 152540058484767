<script lang="ts" setup>
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';

// Composables
const { t } = useI18n();

// Props & emits
const modal = defineModel<boolean>();
const props = defineProps<{
  productId: number | null;
}>();

// Data
const submiting = ref(false);

// Service
const productService = productStore();

// Form
const schema = yup.object({
  label: yup.string().required(t('product.form.label_is_required')),
});
const { handleSubmit, setValues } = useForm<{ label: string }>({
  validationSchema: schema,
});

const submit = handleSubmit(async (values) => {
  submiting.value = true;
  if (!props.productId) return;
  productService.updateProduct(props.productId, values);
  submiting.value = false;
  modal.value = false;
});

watch(
  () => props.productId,
  () => {
    if (props.productId) {
      const product = productService.getProduct(props.productId);
      if (product && product.label) setValues({ label: product.label });
    }
  },
);
</script>
<template>
  <ui-modal v-model="modal" :title="$t('product.modal.manage_product')" width="400">
    <ui-form-input-text :label="$t('product.label')" name="label" :placeholder="$t('product.form.label_placeholder')" />
    <template #footer>
      <ui-button color="secondary" @click="modal = false">{{ $t('global.cancel') }}</ui-button>
      <ui-button :loading="submiting" @click="submit()">{{ $t('global.save') }}</ui-button>
    </template>
  </ui-modal>
</template>
